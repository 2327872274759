<template>
  <b-sidebar
    id="add-new-treatment-sidebar"
    :visible="isAddNewTreatmentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-treatment-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ $t("Add") }}  {{ $t('New') }} Treatment</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Type treatment name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- price -->
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required"
          >
            <b-form-group label="Price" label-for="price">
              <b-form-input
                id="price"
                v-model="stateData.price"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="$$$"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- insurance price -->
          <validation-provider
            #default="validationContext"
            name="insurance_price"
          >
            <b-form-group label="Insurance Price" label-for="insurance_price">
              <b-form-input
                id="insurance_price"
                v-model="stateData.insurance_price"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="$$$"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- dosificacion -->
          <validation-provider
            #default="validationContext"
            name="dosificaion"
            rules=""
          >
            <b-form-group label="Dosification" label-for="dosificaion">
              <b-form-input
                id="dosificaion"
                v-model="stateData.dosificacion"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Treatment"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- unit -->
          <validation-provider
            #default="validationContext"
            name="unit"
            rules=""
          >
            <b-form-group label="Unit" label-for="unit">
              <b-form-input
                id="unit"
                v-model="stateData.unit"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Treatment"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- value_per_unit -->
          <validation-provider
            #default="validationContext"
            name="value_per_edit"
            rules=""
          >
            <b-form-group label="Value per Unit" label-for="value_per_edit">
              <b-form-input
                id="value_per_edit"
                v-model="stateData.value_per_unit"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Treatment"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider
            #default="validationContext"
            name="Category"
            rules="required"
          >
            <b-form-group label="Category" label-for="category">
              <v-select
                id="category"
                v-model="stateData.cat_treatments_id"
                :state="getValidationState(validationContext)"
                :options="optionsCategories"
                :clearable="false"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- informed consent -->
          <validation-provider
            #default="validationContext"
            name="Informed Consent"
            rules="required"
          >
            <b-form-group label="Informed Consent" label-for="informed">
              <v-select
                id="informed"
                v-model="stateData.informed_consent"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(value) => value.value"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(value) => value.value"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import axiosT from "@/services/admin/consults/treatments";
import axiosTC from "@/services/admin/consults/treatmentCategories";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTreatmentSidebarActive",
    event: "update:is-add-new-treatment-sidebar-active",
  },
  props: {
    isAddNewTreatmentSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];

    const optionsCategories = ref([])

    const stateDataTem = {
      name: "",
      status: true,
      price: 0,
      informed_consent: false,
      cat_treatments_id: null,
      insurance_price: 0,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    onMounted(() => {
      loadTypeTreatment();
    });
    const loadTypeTreatment = () => {
      axiosTC.treatmentCategoryList(20).then(({ data }) => {
        optionsCategories.value = data;
      });
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const datos = {
        ...stateData.value,
      };
      axiosT
        .treatmentCreate(datos)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("createTreatment", true);
        })
        .catch(() => {
          loading.value = false;
          emit("createTreatment", false);
        });
    };
    return {
      loading,
      optionsCategories,
      stateData,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style>
</style>