import { userAxios } from '@/services'

const itemList = async (perPage) => {
    try {
        return await userAxios.get(`item/indexAdmin/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemCreate = async (data) => {
    try {
        return await userAxios.post('item', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemUpdate = async (id, data) => {
    try {
        return await userAxios.put(`item/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemDelete = async (id) => {
    try {
        return await userAxios.delete(`item/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`item/indexAdmin/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`item/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`item/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    itemList,
    itemCreate,
    itemUpdate,
    itemDelete,
    itemPagination,
    itemFilter,
    itemFilterPagination
}