import { userAxios } from '@/services'

const itemConfigSetValue = async (data) => {
    try {
        return await userAxios.post(`item_config`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    itemConfigSetValue,
}