import { userAxios } from '@/services'

const treatmentList = async (paginate) => {
    try {
        return await userAxios.get(`treatments/index/${paginate}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentCreate = async (data) => {
    try {
        return await userAxios.post('treatments', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentUpdate = async (id, data) => {
    try {
        return await userAxios.put(`treatments/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentDelete = async (id) => {
    try {
        return await userAxios.delete(`treatments/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`treatments/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`treatments/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`treatments/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    treatmentList,
    treatmentCreate,
    treatmentUpdate,
    treatmentDelete,
    treatmentPagination,
    treatmentFilter,
    treatmentFilterPagination
}